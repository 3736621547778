class TableMappingReadDto {
    DataSourceId: number;
    DatabaseReferenceId: number;
    TableReferenceId: number;

    constructor(
        dataSourceId: number,
        databaseReferenceId: number,
        tableReferenceId: number
    ) {
        this.DataSourceId = dataSourceId;
        this.DatabaseReferenceId = databaseReferenceId;
        this.TableReferenceId = tableReferenceId;
    }
}

export default TableMappingReadDto;
