import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserSession from "./UserSession";

import GetPathSynchroDetailResult from "../classes/api/result/pathsynchromodule/get-pathsynchro-detail-result.js";
import UpdatePathSynchroDetailResult from "../classes/api/result/pathsynchromodule/update-pathsynchro-detail-result.js";
import CreateRequestResult from "../classes/api/result/CreateRequestResult.js";
import GetRequestPathSynchroResult from "../classes/api/result/pathsynchromodule/get-request-pathsynchro-result.js";
import TableMappingReadDto from "../classes/dtos/syncmodule/TableMappingReadDto";
import FieldMappingReadDto from "../classes/dtos/syncmodule/FieldMappingReadDto";
import ScheduleDto from "../classes/dtos/schedulermodule/ScheduleDto";

const PathSynchroApi = {

    GetPathSynchroDetailAsync: async function (pathId) {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url + "/PathSynchro/GetPathSynchroDetail?customerId=" + customerId.toString() + "&pathId=" + pathId.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            var source = data.source != null ? new TableMappingReadDto(data.source.dataSourceId,data.source.databaseReferenceId,data.source.tableReferenceId) : null;
            var target = data.target != null ? new TableMappingReadDto(data.target.dataSourceId,data.target.databaseReferenceId,data.target.tableReferenceId) : null;
            var mappings = data.mappings.map((e) => new FieldMappingReadDto(e.isKey, e.sourceFieldReferenceId, e.targetFieldReferenceId, e.linkedFieldReferenceId));
            var schedule = new ScheduleDto(data.schedule.isActive, data.schedule.scheduleType, data.schedule.interval, data.schedule.time);
            return ProcessingResult.Ok(new GetPathSynchroDetailResult(data.id, data.name, source, target, mappings, schedule));
        }

        return ProcessingResult.Error("Error = "+response.status.toString());
    },

    UpdatePathSynchroDetailAsync : async function (pathId, name, mappings, sourceTableReferenceId, targetTableReferenceId, schedule)
    {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url +"/PathSynchro/UpdatePathSynchroDetail", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({ 'customerId' : customerId, 
                                   'pathId': pathId,
                                   'name': name,
                                   'sourceTableReferenceId' : sourceTableReferenceId,
                                   'targetTableReferenceId' : targetTableReferenceId,
                                   'mappings': mappings.map((e) => { return { isKey: e.IsKey, sourceFieldReferenceId: e.SourceFieldReferenceId, targetFieldReferenceId: e.TargetFieldReferenceId, linkedFieldReferenceId: e.LinkedFieldReferenceId ?? null } }),
                                   'schedule': {
                                       'isActive': schedule.IsActive,
                                       'scheduleType': schedule.Type,
                                       'interval': schedule.Interval,
                                       'time': schedule.Time
                                   }})
        });

        if (response.status === 200)
        {
            var data = await response.json();
            return ProcessingResult.Ok(new UpdatePathSynchroDetailResult(data.id));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    CreateRequestPathSynchroAsync: async function (pathId) {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url + "/PathSynchro/CreateRequestPathSynchro", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({
                'customerId': customerId,
                'pathId': pathId
            })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new CreateRequestResult(data.accepted, data.token));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    GetRequestPathSynchroAsync: async function (taskId) {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url + "/PathSynchro/GetRequestPathSynchro?CustomerId=" + customerId + "&TaskId=" + taskId, {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetRequestPathSynchroResult(data.status, data.progress, data.errorCode));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    }
};

export default PathSynchroApi;