import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserSession from "./UserSession";

import SourceType from "../const/datasourcemodule/SourceType";

import GetDataSourceTypesResult from "../classes/api/result/datasourcemodule/GetDataSourceTypesResult";
import GetDataSourcesResult from "../classes/api/result/datasourcemodule/GetDataSourcesResult";
import EnsureAirtableDataSourceResult from "../classes/api/result/datasourcemodule/EnsureAirtableDataSourceResult";

import CreateRequestResult from "../classes/api/result/CreateRequestResult";
import GetRequestDatabasesResult from "../classes/api/result/datastructuremodule/GetRequestDatabasesResult";
import GetRequestTablesResult from "../classes/api/result/datastructuremodule/GetRequestTablesResult";
import GetRequestFieldsResult from "../classes/api/result/datastructuremodule/GetRequestFieldsResult";
import GetRequestRowsResult from "../classes/api/result/datastructuremodule/GetRequestRowsResult";
import GetRequestTestDataSourceResult from "../classes/api/result/datasourcemodule/GetRequestTestDataSourceResult";
import CreateOrUpdateDataSourceWithCredentialResult from "../classes/api/result/datasourcemodule/CreateOrUpdateDataSourceWithCredentialResult";
import CreateOrUpdateDataSourceStandardDatabaseResult from "../classes/api/result/datasourcemodule/CreateOrUpdateDataSourceStandardDatabaseResult";

import DataSourceTypeListDto from "../classes/dtos/datasourcemodule/DataSourceTypeListDto";
import DataSourceConnectorTypeListDto from "../classes/dtos/datasourcemodule/DataSourceConnectorTypeListDto";
import DataSourceAuthenticationDto from "../classes/dtos/datasourcemodule/DataSourceAuthenticationDto";
import DataSourceAuthenticationDetailDto from "../classes/dtos/datasourcemodule/DataSourceAuthenticationDetailDto";
import DataSourceListDto from "../classes/dtos/datasourcemodule/DataSourceListDto";
import DatabaseReferenceIdAndKeyDto from "../classes/dtos/DatabaseReferenceIdAndKeyDto";
import TableReferenceIdAndKeyDto from "../classes/dtos/TableReferenceIdAndKeyDto";
import FieldReferenceIdAndKeyDto from "../classes/dtos/FieldReferenceIdAndKeyDto";
import FieldTypeReferenceDto from "../classes/dtos/FieldTypeReferenceDto";
import DataSourceStandardDatabaseDto from "../classes/dtos/datasourcemodule/DataSourceStandardDatabaseDto";
import DataSourceWithCredentialDto from "../classes/dtos/datasourcemodule/DataSourceWithCredentialDto";
import DataSourceCommonDto from "../classes/dtos/datasourcemodule/DataSourceCommonDto";

const EnvironmentType = 1; // Standard

const DataSourceApi = {

    GetDataSourceTypesByCustomerAsync: async function (readable: boolean, writable: boolean): Promise<ProcessingResult<GetDataSourceTypesResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        var parameters = "";

        if (readable != null) {
            parameters = parameters + "&readable=" + readable.toString();
        }

        if (writable != null) {
            parameters = parameters + "&writable=" + writable.toString();
        }

        const response = await fetch(Settings.api_url + "/DataSource/GetDataSourceTypesByCustomer?customerId=" + customerId.toString() + "&environment=" + EnvironmentType.toString() + parameters, {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetDataSourceTypesResult(data.dataSourceTypes.map((e) => new DataSourceTypeListDto(e.id, e.name, e.sourceType, e.connectorTypes.map((x) => new DataSourceConnectorTypeListDto(x.id, x.name)), e.authentications.map((x) => new DataSourceAuthenticationDto(x.label, x.key, x.details.map((y) => new DataSourceAuthenticationDetailDto(y.label, y.key))))))));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    GetDataSourcesByCustomerAsync: async function (readable: boolean, writable: boolean): Promise<ProcessingResult<GetDataSourcesResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        var parameters = "";

        if (readable != null) {
            parameters = parameters + "&readable=" + readable.toString();
        }

        if (writable != null) {
            parameters = parameters + "&writable=" + writable.toString();
        }

        const response = await fetch(Settings.api_url + "/DataSource/GetDataSourcesByCustomer?customerId=" + customerId.toString() + "&environment=" + EnvironmentType.toString() + parameters, {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetDataSourcesResult(data.dataSources.map((e) => new DataSourceListDto(e.id, e.name, e.type, e.connectorType))));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    CreateDataSourceStandardDatabaseAsync: async function (customerAgentId: number, dataSourceType: number, name: string, connectionString: string): Promise<ProcessingResult<CreateOrUpdateDataSourceStandardDatabaseResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSource/CreateDataSourceStandardDatabase", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({
                'customerId': customerId,
                'dataSource':
                {
                    'customerAgentId': customerAgentId,
                    'type': dataSourceType,
                    'name': name,
                    'connectionString': connectionString
                }
            })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new CreateOrUpdateDataSourceStandardDatabaseResult(data.id));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    UpdateDataSourceStandardDatabaseAsync: async function (id: number, customerAgentId: number, dataSourceType: number, name: string, connectionString: string): Promise<ProcessingResult<CreateOrUpdateDataSourceStandardDatabaseResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSource/UpdateDataSourceStandardDatabase", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({
                'customerId': customerId,
                'dataSource':
                {
                    'id': id,
                    'customerAgentId': customerAgentId,
                    'type': dataSourceType,
                    'name': name,
                    'connectionString': connectionString
                }
            })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new CreateOrUpdateDataSourceStandardDatabaseResult(data.id));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    CreateDataSourceWithCredentialAsync: async function (dataSourceType: number, dataSourceConnectorType: number, name: string, customerAgentId: number, dataSourceCredentialId: number): Promise<ProcessingResult<CreateOrUpdateDataSourceWithCredentialResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSource/CreateDataSourceWithCredential", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({
                'customerId': customerId,
                'dataSource':
                {
                    'customerAgentId': customerAgentId,
                    'type': dataSourceType,
                    'connectorType': dataSourceConnectorType,
                    'name': name,
                    'dataSourceCredentialId': dataSourceCredentialId
                }
            })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new CreateOrUpdateDataSourceWithCredentialResult(data.id));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    UpdateDataSourceWithCredentialAsync: async function (id: number, dataSourceType: number, dataSourceConnectorType: number, name: string, customerAgentId: number, dataSourceCredentialId: number): Promise<ProcessingResult<CreateOrUpdateDataSourceWithCredentialResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSource/UpdateDataSourceWithCredential", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({
                'customerId': customerId,
                'dataSource':
                {
                    'id': id,
                    'customerAgentId': customerAgentId,
                    'type': dataSourceType,
                    'connectorType': dataSourceConnectorType,
                    'name': name,
                    'dataSourceCredentialId': dataSourceCredentialId
                }
            })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new CreateOrUpdateDataSourceWithCredentialResult(data.id));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    GetDataSourceByIdAsync: async function (id: number): Promise<ProcessingResult<DataSourceCommonDto>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        var parameters = "&dataSourceId=" + id;

        const response = await fetch(Settings.api_url + "/DataSource/GetDataSourceById?customerId=" + customerId.toString() + parameters, {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            if (data.dataSource.sourceType === SourceType.WithIncludedCredentials) {
                return ProcessingResult.Ok(new DataSourceStandardDatabaseDto(data.dataSource.id, data.dataSource.name, data.dataSource.type, data.dataSource.connectorType, data.dataSource.customerAgentId, data.dataSource.connectionString));
            }
            if (data.dataSource.sourceType === SourceType.WithSeparatedCredentials) {
                return ProcessingResult.Ok(new DataSourceWithCredentialDto(data.dataSource.id, data.dataSource.name, data.dataSource.type, data.dataSource.connectorType, data.dataSource.customerAgentId, data.dataSource.dataSourceCredentialId));
            }
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    EnsureAirtableDataSourceAsync: async function (workspaceId: string, name: string): Promise<ProcessingResult<EnsureAirtableDataSourceResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSource/EnsureAirtableDataSource", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({
                'customerId': customerId,
                'workspaceId': workspaceId,
                'name': name
            })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new EnsureAirtableDataSourceResult(new DataSourceListDto(data.dataSource.id, data.dataSource.name, data.dataSource.type, data.dataSource.connectorType)));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    CreateRequestDatabasesAsync: async function (dataSourceId: number): Promise<ProcessingResult<CreateRequestResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSource/CreateRequestDatabases", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({
                'customerId': customerId,
                'dataSourceId': dataSourceId
            })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new CreateRequestResult(data.accepted, data.token));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    GetRequestDatabasesAsync: async function (taskId: string): Promise<ProcessingResult<GetRequestDatabasesResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSource/GetRequestDatabases?CustomerId=" + customerId + "&TaskId=" + taskId, {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetRequestDatabasesResult(data.status, data.databaseReferences.map((e) => new DatabaseReferenceIdAndKeyDto(e.id, e.key, e.name))));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    CreateRequestTablesAsync: async function (dataSourceId: number, databaseReferenceId: number): Promise<ProcessingResult<CreateRequestResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSource/CreateRequestTables", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({
                'customerId': customerId,
                'dataSourceId': dataSourceId,
                'databaseReferenceId': databaseReferenceId
            })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new CreateRequestResult(data.accepted, data.token));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    GetRequestTablesAsync: async function (taskId: string): Promise<ProcessingResult<GetRequestTablesResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSource/GetRequestTables?CustomerId=" + customerId + "&TaskId=" + taskId, {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetRequestTablesResult(data.status, data.tableReferences.map((e) => new TableReferenceIdAndKeyDto(e.id, e.key, e.name, e.type, e.readOnly, e.informativeRowCount))));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    CreateRequestFieldsAsync: async function (dataSourceId, tableReferenceId): Promise<ProcessingResult<CreateRequestResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSource/CreateRequestFields", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({
                'customerId': customerId,
                'dataSourceId': dataSourceId,
                'tableReferenceId': tableReferenceId
            })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new CreateRequestResult(data.accepted, data.token));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    GetRequestFieldsAsync: async function (taskId: string): Promise<ProcessingResult<GetRequestFieldsResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSource/GetRequestFields?CustomerId=" + customerId + "&TaskId=" + taskId, {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetRequestFieldsResult(data.status, data.fieldReferences.map((e) => new FieldReferenceIdAndKeyDto(e.id, e.sourceKey, e.datasetKey, e.name, new FieldTypeReferenceDto(e.type.type, e.type.typeLabel, e.type.isNullable, e.type.maxLength, e.type.originTypeLabel, e.type.linkTableId)))));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    CreateRequestRowsAsync: async function (dataSourceId: number, tableReferenceId: number, fieldReferenceIds: number[], maximumRowCount: number | null): Promise<ProcessingResult<CreateRequestResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSource/CreateRequestRows", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({
                'customerId': customerId,
                'dataSourceId': dataSourceId,
                'tableReferenceId': tableReferenceId,
                'fieldReferenceIds': fieldReferenceIds,
                'maximumRowCount': maximumRowCount
            })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new CreateRequestResult(data.accepted, data.token));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    GetRequestRowsAsync: async function (taskId: string): Promise<ProcessingResult<GetRequestRowsResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSource/GetRequestAllRows?CustomerId=" + customerId + "&TaskId=" + taskId + "&WithRowId=true", {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetRequestRowsResult(data.status, data.rows));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    CreateRequestTestDataSourceAsync: async function (dataSourceId: number): Promise<ProcessingResult<CreateRequestResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSource/CreateRequestTestDataSource", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({
                'customerId': customerId,
                'dataSourceId': dataSourceId
            })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new CreateRequestResult(data.accepted, data.token));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    GetRequestTestDataSourceAsync: async function (taskId: string): Promise<ProcessingResult<GetRequestTestDataSourceResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataSource/GetRequestTestDataSource?CustomerId=" + customerId + "&TaskId=" + taskId, {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetRequestTestDataSourceResult(data.status, data.ok, data.error));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    }
};

export default DataSourceApi;