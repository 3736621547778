import React, { useEffect, useState } from 'react';

//import { Box, Label, Select, Switch } from '@airtable/blocks/ui';

import FieldType from './../../frontend/components/datasourcemodule/field-type';
import FieldMappingDto from './../../frontend/classes/dtos/pathreportingmodule/FieldMappingDto';

import Label from './../wrapper/label';
import Switch from './../wrapper/switch';

import './../../common.css';

function PathReportingField(props) {

    var mappingLine = props.mapping.Mappings.find((x) => x.SourceFieldReferenceId === props.sourceFieldReferenceId)

    let isQueryableRead = false;
    let isRequiredRead = false;
    let defaultValueRead = null;
    let isExposedRead = false;

    if (mappingLine != null) {
        isQueryableRead = mappingLine.IsQueryable;
        isRequiredRead = mappingLine.IsRequired;
        defaultValueRead = mappingLine.DefaultValue;
        isExposedRead = mappingLine.IsExposed;
    }

    const [isQueryable, setIsQueryable] = useState(isQueryableRead);
    const [isRequired, setIsRequired] = useState(isRequiredRead);
    const [defaultValue, setDefaultValue] = useState(defaultValueRead);
    const [isExposed, setIsExposed] = useState(isExposedRead);

    const ensureMappingLine = () => {

        var mappingLine = props.mapping.Mappings.find((x) => x.SourceFieldReferenceId === props.sourceFieldReferenceId);

        if (mappingLine === undefined) {

            mappingLine = new FieldMappingDto(props.sourceFieldReferenceId, false, false, null, false);

            setIsQueryable(mappingLine.IsQueryable);
            setIsRequired(mappingLine.IsRequired);
            setDefaultValue(mappingLine.DefaultValue);
            setIsExposed(mappingLine.IsExposed);

            props.mapping.Mappings.push(mappingLine);
        }

        return mappingLine;
    }

    const changeIsQueryable = (value) => {
        var mappingLine = ensureMappingLine();
        mappingLine.IsQueryable = value;
        props.setMapping(props.mapping);

        setIsQueryable(value);
    }

    const changeIsRequired = (value) => {
        var mappingLine = ensureMappingLine();
        mappingLine.IsRequired = value;
        props.setMapping(props.mapping);
        setIsRequired(value);
    }
    const changeIsExposed = (value) => {
        var mappingLine = ensureMappingLine();
        mappingLine.IsExposed = value;
        props.setMapping(props.mapping);
        setIsExposed(value);
    }

    return (
        <tr key={props.index} >
            <td className="align-middle">
                <div className="start-line">
                    <Label width='100%'>{props.field.Name}</Label>
                    <FieldType field={props.field} />
                </div>
            </td>
            <td className="align-middle"><Switch value={isExposed} onChange={changeIsExposed}></Switch></td>

        </tr>);
}

export default PathReportingField;