import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserSession from "./UserSession";

import GetDatabaseReferencesResult from "../classes/api/result/datastructuremodule/GetDatabaseReferencesResult";
import UpsertDatabaseReferencesResult from "../classes/api/result/datastructuremodule/UpsertDatabaseReferencesResult";
import DatabaseReferenceIdAndKeyDto from "../classes/dtos/DatabaseReferenceIdAndKeyDto";
import GetTableReferencesResult from "../classes/api/result/datastructuremodule/GetTableReferencesResult";
import UpsertTableReferencesResult from "../classes/api/result/datastructuremodule/UpsertTableReferencesResult";
import TableReferenceIdAndKeyDto from "../classes/dtos/TableReferenceIdAndKeyDto";
import GetFieldReferencesResult from "../classes/api/result/datastructuremodule/GetFieldReferencesResult";
import UpsertFieldReferencesResult from "../classes/api/result/datastructuremodule/UpsertFieldReferencesResult";
import FieldReferenceIdAndKeyDto from "../classes/dtos/FieldReferenceIdAndKeyDto";
import FieldTypeReferenceDto from "../classes/dtos/FieldTypeReferenceDto";

const DataStructureApi = {

    GetDatabaseReferencesAsync: async function (dataSourceId: number): Promise<ProcessingResult<GetDatabaseReferencesResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataStructure/GetDatabaseReferences?customerId=" + customerId.toString() + "&dataSourceId=" + dataSourceId.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetDatabaseReferencesResult(data.lastUpdateDate, data.databases.map((e)=> new DatabaseReferenceIdAndKeyDto(e.id,e.key,e.name))));
        }

        return ProcessingResult.Error("Error = "+response.status.toString());
    },

    GetTableReferencesAsync: async function (databaseReferenceId: number): Promise<ProcessingResult<GetTableReferencesResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataStructure/GetTableReferences?customerId=" + customerId.toString() + "&databaseReferenceId=" + databaseReferenceId.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetTableReferencesResult(data.lastUpdateDate, data.tables.map((e) => new TableReferenceIdAndKeyDto(e.id, e.key, e.name, e.type, e.readOnly, e.informativeRowCount))));
        }

        return ProcessingResult.Error("Error = "+response.status.toString());
    },

    GetFieldReferencesAsync: async function (tableReferenceId: number): Promise<ProcessingResult<GetFieldReferencesResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/DataStructure/GetFieldReferences?customerId=" + customerId.toString() + "&tableReferenceId=" + tableReferenceId.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();

            return ProcessingResult.Ok(new GetFieldReferencesResult(data.lastUpdateDate, data.fields.map((e) => new FieldReferenceIdAndKeyDto(e.id, e.sourceKey, e.datasetKey, e.name, new FieldTypeReferenceDto(e.type.type, e.type.typeLabel, e.type.isNullable, e.type.maxLength, e.type.originTypeLabel, e.type.linkTableId)))));
        }

        return ProcessingResult.Error("Error = "+response.status.toString());
    },

    UpsertDatabaseReferencesAsync: async function (databaseDataSourceId: number, databases: DatabaseReferenceIdAndKeyDto[]): Promise<ProcessingResult<UpsertDatabaseReferencesResult>>
    {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url+"/DataStructure/UpsertDatabaseReferences", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({ 'customerId' : customerId, 
                                   'databaseDataSourceId': databaseDataSourceId,
                                   'databases': databases
                                 })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new UpsertDatabaseReferencesResult(data.lastUpdateDate, data.databases.map((e)=> new DatabaseReferenceIdAndKeyDto(e.id,e.key,e.name))));
        }

        return ProcessingResult.Error("Error = "+response.status.toString());
    },

    UpsertTableReferencesAsync: async function (databaseDataSourceId: number, databaseReferenceId: number, tables: TableReferenceIdAndKeyDto[]): Promise<ProcessingResult<UpsertTableReferencesResult>>
    {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url+"/DataStructure/UpsertTableReferences", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({ 'customerId' : customerId, 
                                   'databaseDataSourceId': databaseDataSourceId,
                                   'databaseReferenceId': databaseReferenceId,
                                   'tables': tables
                                 })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new UpsertTableReferencesResult(data.lastUpdateDate, data.tables.map((e) => new TableReferenceIdAndKeyDto(e.id, e.key, e.name, e.type, e.readOnly, e.informativeRowCount))));
        }

        return ProcessingResult.Error("Error = "+response.status.toString());
    },

    UpsertFieldReferencesAsync: async function (databaseDataSourceId: number, tableReferenceId: number, fields: FieldReferenceIdAndKeyDto[]): Promise<ProcessingResult<UpsertFieldReferencesResult>>
    {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url+"/DataStructure/UpsertFieldReferences", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({ 'customerId' : customerId, 
                                   'databaseDataSourceId': databaseDataSourceId,
                                   'tableReferenceId': tableReferenceId,
                                   'fields': fields
                                 })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new UpsertFieldReferencesResult(data.lastUpdateDate, data.fields.map((e) => new FieldReferenceIdAndKeyDto(e.id, e.sourceKey, e.datasetKey, e.name, new FieldTypeReferenceDto(e.type.type, e.type.typeLabel, e.type.isNullable, e.type.maxLength, e.type.originTypeLabel, e.type.linkTableId)))));
        }

        return ProcessingResult.Error("Error = "+response.status.toString());
    }
};

export default DataStructureApi;