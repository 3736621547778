class GetPathSynchroDetailResult {
    constructor(id, name, source, target, mappings, schedule) {
        this.Id = id;
        this.Name = name;
        this.Source = source;
        this.Target = target;
        this.Mappings = mappings;
        this.Schedule = schedule;
    }
}

export default GetPathSynchroDetailResult;
