import React, { useEffect, useState } from "react";

//import { Button, FormField, Input } from "@airtable/blocks/ui";
import Button from './../../../components/wrapper/button';

import Dialog from '../framework/dialog.js';
import DialogFooter from "../framework/dialog-footer.js";

import ActionConst from "../action-const";

import DataSourceCredentialApi from '../../apis/DataSourceCredentialApi';

import DataSourceCredentialDialogBody from '../datasourcemodule/datasourcecredential-dialog-body';

import DataSourceCredentialCrudLogic from "../../businesslayers/datasourcecredentialmodule/datasourcecredential-crud-logic";

import '../../styles/nowcode-toolboxline.css';

const DataSourceCredentialDialog = (props) => {

  const [name, setName] = useState("");
  const [values, setValues] = useState({});

  const [invalid, setInvalid] = useState(false);

  const [current, setCurrent] = useState(null);

  const [crudLogic, setCrudLogic] = useState(null);

    if (crudLogic === null) {
        setCrudLogic(new DataSourceCredentialCrudLogic());
    }

    useEffect(() => {
        if (crudLogic === null) {
            return;
        }
        if (current === null) {
            crudLogic.CurrentDataSourceAccessId = null;
        } else {
            crudLogic.CurrentDataSourceAccessId = current.Id;
        }
    }, [current, crudLogic])

    const isValid = function () {
        if (name === null || name.length === 0) {
            return false;
        }
        let ok = true;
        props.dataSourceType.Authentications[0].Details.forEach((e) => {
            if (values[e.Name] === null || values[e.Name] === undefined || values[e.Name].length === 0) {
                ok = false;
            }
        });
        return ok;
  }

  const createOrUpdateDataSourceCredential = function () {

      crudLogic.CreateOrUpdateDataSourceAccessAsync(name, props.dataSourceType.Id, values).then((processingResult) => {
          if (processingResult.Result.Ok) {
              props.setDialogIsOpen(false);
              props.setDataSourceCredentialCreated(processingResult.Result.Id);
              props.setDataSourceCredentials(null);
          }
          else {
              setInvalid(true);
          }
      });
  };

  const cancel = function() {
      props.setDialogIsOpen(false);
  }

  useEffect(() => {
      if (current == null) {
          if (props.currentDataSourceAccess != null) {
              DataSourceCredentialApi.GetDataSourceAccessByIdAsync(props.currentDataSourceAccess.Id).then((e) => {
                  setName(e.Result.Name);
                  setValues(e.Result.Parameters);
                  setCurrent(e.Result);
              });
          } else {
              setValues({ Type: props.dataSourceType.Authentications[0].Key });
          }
      }
  }, [props.currentDataSourceAccess, current, props.dataSourceType.Id, props.dataSourceType.Authentications])

    // Only one authentication type for the moment
  let titleSource = props.dataSourceType.Authentications[0].Label;

  let title = "???";
  switch (props.action) {
    case ActionConst.Create: title = "Create a " + titleSource; break;
    case ActionConst.Update: title = "Update a " + titleSource; break;
    case ActionConst.Delete: title = "Delete a " + titleSource; break;
    default: return "???";
  }

  return (
    <React.Fragment>
      {(
        <Dialog onClose={() => cancel()} title={title} width="320px">
          <DataSourceCredentialDialogBody name={name} setName={setName} values={values} setValues={setValues} authentications={props.dataSourceType.Authentications} invalid={invalid} />
          <DialogFooter>
          <div className="nowcode-dialog-footer">
              {(props.action === ActionConst.Create) ? (<Button marginRight={1} onClick={createOrUpdateDataSourceCredential} disabled={!isValid()}>Create</Button>) : ("")}
              {(props.action === ActionConst.Update) ? (<Button marginRight={1} onClick={createOrUpdateDataSourceCredential} disabled={!isValid()}>Update</Button>) : ("")}
              <Button onClick={cancel}>Cancel</Button>
            </div>
          </DialogFooter>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default DataSourceCredentialDialog;