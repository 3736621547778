import React, { useState, useEffect, useCallback } from 'react';
import { Nav, NavItem, NavLink, Button, Spinner } from 'reactstrap';
import { useNavigate, useParams } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import ScheduleDto from '../frontend/classes/dtos/schedulermodule/ScheduleDto';

import PathSynchroApi from "./../frontend/apis/pathsynchro-api";

import KeyLogic from './../frontend/businesslayers/datasourcemodule/key-logic';

import { PathSynchroSource } from './pathsynchro/PathSynchroSource';
import { PathSynchroTarget } from './pathsynchro/PathSynchroTarget';
import { PathSynchroMapping } from './pathsynchro/PathSynchroMapping';
import { PathSynchroStarter } from './pathsynchro/PathSynchroStarter';
import { PathSynchroRuns } from './pathsynchro/PathSynchroRuns';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

import Input from './../components/wrapper/input';

import '../common.css';
import './pathsynchro/pathsynchro.css';

const PathSynchroTab = {
    Source: 1,
    Target: 2,
    Mapping: 3,
    Preview: 4,
    Starter: 5,
    Runs: 6
}
export function PathSynchro() {

    let params = useParams();

    const navigate = useNavigate();

    // Data
    const [currentPathId] = useState(parseInt(params.id));

    const [pathName, setPathName] = useState("");

    const [mapping, setMapping] = useState(null);

    const [currentDataSource1, setCurrentDataSource1] = useState(null);
    const [currentDataSource2, setCurrentDataSource2] = useState(null);

    const [currentDatabaseReference1, setCurrentDatabaseReference1] = useState(null);
    const [currentDatabaseReference2, setCurrentDatabaseReference2] = useState(null);

    const [currentTableReference1, setCurrentTableReference1] = useState(null);
    const [currentTableReference2, setCurrentTableReference2] = useState(null);

    const [fields1, setFields1] = useState(null);
    const [fields2, setFields2] = useState(null);

    const [fullLoadingInProgress, setFullLoadingInProgress] = useState(true);

    const [syncAvailable, setSyncAvailable] = useState(false);

    const [checkToDo, setCheckToDo] = useState(false);

    const [scheduleIsActive, setScheduleIsActive] = useState(false);
    const [scheduleType, setScheduleType] = useState(0);
    const [scheduleInterval, setScheduleInterval] = useState(15);
    const [scheduleTime, setScheduleTime] = useState("08:00");

    useEffect(() => {
        setCheckToDo(true);
    }, [fields1, fields2]);

    const check = useCallback(async () => {
        if (checkToDo === true) {

            if (mapping === null) {
                setSyncAvailable(false);
                setCheckToDo(false);
                return;
            }

            if (mapping.Mappings === null) {
                setSyncAvailable(false);
                setCheckToDo(false);
                return;
            }

            setSyncAvailable(KeyLogic.Check(mapping.Mappings));

            setCheckToDo(false);
        }
    }, [checkToDo]);

    useEffect(() => {
        check()
    }, [check]);

    useEffect(() => {
        if (mapping != null) {

            if ((mapping.Source.TableReferenceId === 0 || (mapping.Source.TableReferenceId > 0 && fields1 != null)) &&
                (mapping.Target.TableReferenceId === 0 || (mapping.Target.TableReferenceId > 0 && fields2 != null))) {
                setFullLoadingInProgress(false);
            }
        }

    }, [mapping, fields1, fields2])

    function changePathName(value) {

        setPathName(value);
    }

    const fetchResult = useCallback(async () => {
        if (!mapping) {
            var mappingResult = await PathSynchroApi.GetPathSynchroDetailAsync(currentPathId);

            if (mappingResult.IsFailed()) {
                alert("Error during GetPathSynchroDetailAsync : " + mappingResult.ErrorMessage);
                return;
            }

            setPathName(mappingResult.Result.Name)
            setMapping(mappingResult.Result);
            setScheduleIsActive(mappingResult.Result.Schedule.IsActive)
            setScheduleType(mappingResult.Result.Schedule.Type)
            setScheduleInterval(mappingResult.Result.Schedule.Interval);
            setScheduleTime(mappingResult.Result.Schedule.Time);
        }
    }, [currentPathId, mapping]);

    useEffect(() => {
        fetchResult()
    }, [fetchResult]);

    // Actions

    const backToList = async () => {
        navigate('/Paths');
    }

    const savePath = async () => {

        let schedule = new ScheduleDto(scheduleIsActive, scheduleType, scheduleInterval, scheduleTime);

        var filtered = [];
        mapping.Mappings.forEach((element) => {
            if (fields1.find(e => e.Id === element.SourceFieldReferenceId) && fields2.find(e => e.Id === element.TargetFieldReferenceId)) {
                filtered.push(element);
            }
        });

        mapping.Mappings = filtered;

        let updateResult = await PathSynchroApi.UpdatePathSynchroDetailAsync(currentPathId, pathName, mapping.Mappings, currentTableReference1.Id, currentTableReference2.Id, schedule);

        if (updateResult.IsFailed()) {
            alert("Error during UpdatePathSynchroDetailAsync : " + updateResult.ErrorMessage);
            return;
        }
    }

    // UX
    const [currentTab, setCurrentTab] = useState(PathSynchroTab.Source);

    function getActive(tab) {
        switch (tab) {
            default:
                return currentTab === tab;
        }
    }

    function getDisabled(tab) {
        switch (tab) {
            case PathSynchroTab.Source:
                return false;
            case PathSynchroTab.Target:
                return false;
            case PathSynchroTab.Mapping:
                return currentTableReference1 === null || currentTableReference2 === null;
            case PathSynchroTab.Preview:
                return true;
            case PathSynchroTab.Starter:
                return currentTableReference1 === null || currentTableReference2 === null;
            case PathSynchroTab.Runs:
                return currentTableReference1 === null || currentTableReference2 === null; // TODO KEYS check
            default:
                return true;
        }
    }

    function getClassName(tab) {
        if (getActive(tab)) {
            return "";
        }
        else {
            return "nowcode-hidden";
        }
    }

    function click(tab) {
        if (tab !== currentTab) {
            setCurrentTab(tab);
        }
    }

    function getClassNameForLoadingContent(inProgress) {
        if (inProgress) {
            return 'path-synchro nowcode-hidden';
        } else {
            return 'path-synchro';
        }
    }

    function getClassNameForLoadingSpinner(inProgress) {
        if (inProgress) {
            return 'spinner';
        } else {
            return 'spinner nowcode-hidden';
        }
    }

    return (
        <div>
            <AuthenticatedTemplate>
                <Spinner color="primary" className={getClassNameForLoadingSpinner(fullLoadingInProgress)}>Chargement en cours</Spinner>
                <div className={getClassNameForLoadingContent(fullLoadingInProgress)}>
                    <div className='nowcode-internal-block nowcode-internal-space-bottom start-line'>
                        <button className="nowcode-internal-space-right btn-icon" onClick={backToList}><FontAwesomeIcon icon={faAngleLeft} /></button>
                        <div style={{ width: '5px' }}></div>
                        <Input className='nowcode-internal-space-right' value={pathName} onChange={e => changePathName(e.target.value)} />
                        <Button color="primary" className='nowcode-internal-space-right' onClick={savePath}>Save</Button>
                    </div>
                    <div className='nowcode-internal-block nowcode-internal-space-bottom'>
                        <Nav justified pills>
                            <NavItem>
                                <NavLink active={getActive(PathSynchroTab.Source)} disabled={getDisabled(PathSynchroTab.Source)} onClick={() => click(PathSynchroTab.Source)} href="#">Source</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={getActive(PathSynchroTab.Target)} disabled={getDisabled(PathSynchroTab.Target)} onClick={() => click(PathSynchroTab.Target)} href="#" >Target</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={getActive(PathSynchroTab.Mapping)} disabled={getDisabled(PathSynchroTab.Mapping)} onClick={() => click(PathSynchroTab.Mapping)} href="#">Mapping</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={getActive(PathSynchroTab.Preview)} disabled={getDisabled(PathSynchroTab.Preview)} onClick={() => click(PathSynchroTab.Preview)} href="#">Preview</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={getActive(PathSynchroTab.Starter)} disabled={getDisabled(PathSynchroTab.Starter)} onClick={() => click(PathSynchroTab.Starter)} href="#">Schedule</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={getActive(PathSynchroTab.Runs)} disabled={getDisabled(PathSynchroTab.Runs)} onClick={() => click(PathSynchroTab.Runs)} href="#">Runs</NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    <div className={getClassName(PathSynchroTab.Source)}>
                        <PathSynchroSource
                            currentDataSource={currentDataSource1}
                            setCurrentDataSource={setCurrentDataSource1}
                            currentDatabaseReference={currentDatabaseReference1}
                            setCurrentDatabaseReference={setCurrentDatabaseReference1}
                            currentTableReference={currentTableReference1}
                            setCurrentTableReference={setCurrentTableReference1}
                            fields={fields1}
                            setFields={setFields1}
                            mapping={mapping}
                            fullLoadingInProgress={fullLoadingInProgress}
                        />
                    </div>
                    <div className={getClassName(PathSynchroTab.Target)}>
                        <PathSynchroTarget
                            currentDataSource={currentDataSource2}
                            setCurrentDataSource={setCurrentDataSource2}
                            currentDatabaseReference={currentDatabaseReference2}
                            setCurrentDatabaseReference={setCurrentDatabaseReference2}
                            currentTableReference={currentTableReference2}
                            setCurrentTableReference={setCurrentTableReference2}
                            fields={fields2}
                            setFields={setFields2}
                            mapping={mapping}
                            fullLoadingInProgress={fullLoadingInProgress}
                        />
                    </div>
                    <div className={getClassName(PathSynchroTab.Mapping)}>
                        <PathSynchroMapping
                            currentDataSource1={currentDataSource1}
                            currentDataSource2={currentDataSource2}
                            currentDatabaseReference1={currentDatabaseReference1}
                            currentDatabaseReference2={currentDatabaseReference2}
                            currentTableReference1={currentTableReference1}
                            currentTableReference2={currentTableReference2}
                            fields1={fields1}
                            fields2={fields2}
                            setCheckToDo={setCheckToDo}
                            mapping={mapping}
                            setMapping={setMapping}
                            fullLoadingInProgress={fullLoadingInProgress}
                        />
                    </div>
                    <div className={getClassName(PathSynchroTab.Starter)}>
                        <PathSynchroStarter
                            scheduleIsActive={scheduleIsActive}
                            setScheduleIsActive={setScheduleIsActive}
                            scheduleType={scheduleType}
                            setScheduleType={setScheduleType}
                            scheduleInterval={scheduleInterval}
                            setScheduleInterval={setScheduleInterval}
                            scheduleTime={scheduleTime}
                            setScheduleTime={setScheduleTime}
                        />
                    </div>
                    <div className={getClassName(PathSynchroTab.Runs)}>
                        <PathSynchroRuns
                            currentPathId={currentPathId}
                            syncAvailable={syncAvailable}
                            mapping={mapping}
                        />
                    </div>
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
            </UnauthenticatedTemplate>
        </div>
    );
}
